import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull';
import Clients from '../components/sections/Clients';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplit from '../components/sections/FeaturesSplit';
//import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';


class Home extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'By providing end to end solutions to make fish farming smarter, we help the farmers while feeding humanity sustainibly.'
    }

    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <FeaturesTiles />
        <GenericSection topDivider className="center-content">
          <SectionHeader data={genericSection01Header} className="reveal-from-bottom" />
          <div className="reveal-from-bottom">
              <Image
                src={require('./../assets/images/aquanaut.svg')}
                alt="how"
                width={1500}
                height={1500} />
          </div>
        </GenericSection>        
        <FeaturesSplit invertMobile topDivider imageFill />
        {/* <Pricing topDivider pricingSwitcher /> */}
        <Cta split />
      </React.Fragment>
    );
  }
}

export default Home;